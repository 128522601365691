@import "breakpoints.scss";

#imageViewer {
  flex-direction: row;
  height: 100%;
  z-index: 10;
  @include sm {
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @include xs {
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.divider {
  width: 5px;
  background-color: black;
  height: 100%;
  pointer-events: all;
  cursor: col-resize;

  @include md {
    width: 7px;
  }

  @include sm {
    display: none;
  }

  @include xs {
    display: none;
  }
}

.toolTipMenu {
  padding: 10px;
  margin: -3px -7px -3px -7px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.controlContainer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 12;

  .buttonContainer,
  .MuiCollapse-wrapperInner {
    width: 100%;
    max-width: 100%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .buttonGroup {
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      button {
        min-width: fit-content;
        padding: 5px 10px 5px 10px;
        border-right: 1px solid #ddd;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        &:last-child {
          border-right: none;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }
}
rect.highlighted {
  stroke: white;
  stroke-width: 2px;
}
