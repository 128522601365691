.listViewWrapperPreviousDefects {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
    position: absolute;
    top: 56px;
    right: 0px;
    height: calc(100vh - 58px);
    background-color: rgba(255, 255, 255, 0.5);
    width: calc(100vw - 45px);

    @media (max-width: 900px) {
        height: calc(100vh - 140px);
        top: 90px;
        width: 100vw;
      }

    .list {
        background-color: #eaeff1;
        height: calc(100vh - 80px);
        width: 369px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
        overflow-y: scroll;
        gap: 6px;
    }

    .listChild {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .listViewItem{
        width: 280px;
        height: 30px;
        margin-right: 19px;
        background: rgba(0, 106, 234, 0.15);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 24px;
        margin-top: 3px;
    }

    .listViewPoleItem{
        width: 330px;
        height: 30px;
        background: rgba(0, 106, 234, 0.49);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0;
    }

    .listItemStart{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        cursor: pointer;
    }

    .listItemEnd{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;
        cursor: pointer;
    }

    .listViewImageSection {
        display: flex;
        flex-direction: row;
    }


    .listSeverityCount{
        color: #FFF;
        text-align: center;
        font-size: 16px;
        height: 20px;
        border-radius: 3px;
        margin: 0 8px;
        padding: 0 12px;
        background-color: #E42E2E;
        min-width: 11px;
    }

        .listSeverityProcessedCount{
        color: #FFF;
        text-align: center;
        font-size: 16px;
        height: 20px;
        border-radius: 3px;
        padding: 0 12px;
        margin-right: 5px;
        background-color:#3CE42E;
    }
}

.paginationContainer{

    background-color: #eaeff1;
    height: 80px;
    width: 369px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
    justify-content: center;

    .paginationWrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
}
